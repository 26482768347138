import React from "react";
import { PageLoading } from "../components/PageLoading/PageLoading";
import { useEffect, useRef, useMemo, useState } from "react";
import { Flex, Table, TextField } from "@radix-ui/themes";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { router } from "./../App";

import {
  clustersOverviewPageSelector,
  gotFilter,
  clear,
  showAddModal,
  closeModal,
  addCluster,
} from "../app/clustersOverviewPageReducer";
import * as layout from "../app/layoutReducer";
import Button from "../components/Button/Button";
import { Error } from "../components/Error";
import styles from "./ClustersOverviewPage.module.scss";
import { YesNo } from "../components/YesNo";
import CloudProvider from "../components/CloudProvider";
import {
  ClusterListModel,
  useListClustersV1ClustersGetQuery,
} from "../app/api/ide/ideService";
import { AddEditClusterForm } from "../AddEditClusterForm/AddEditClusterForm";
import { Modal } from "../components/Modal/Modal";
import { notAsked } from "srd";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
  ColumnDef,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { Pagination } from "../components/Pagination/Pagination";
import { SortArrows } from "../components/Table/TableSort";

const queryParms = {
  page: 1,
  pageSize: 50,
};

export function ClustersOverviewPage() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(clustersOverviewPageSelector);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { isSuccess } = useListClustersV1ClustersGetQuery(queryParms);

  useEffect(() => {
    dispatch(layout.setBreadcrumbs([layout.plainBreadcrumb("Clusters")]));
    function handleFocus() {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }

    // focus search input on page load
    handleFocus();
  }, []);

  return (
    <div className={styles.page}>
      <Flex justify="between" className={styles.top}>
        <Flex gap="4" direction="column" className={styles.searchInput}>
          <Flex gap="2">
            <div>
              <TextField.Input
                placeholder="Filter..."
                tabIndex={1}
                onInput={(e) => dispatch(gotFilter(e.currentTarget.value))}
                ref={inputRef}
                value={state.filterQuery}
                data-testid="search-input"
              />
            </div>
            <Button color="crimson" onClick={() => dispatch(clear())}>
              Clear
            </Button>
          </Flex>
        </Flex>
        {isSuccess && (
          <Button onClick={() => dispatch(showAddModal())}>Add Cluster</Button>
        )}
      </Flex>
      <ClustersTable filter={state.filterQuery} />
      <AddModal />
    </div>
  );
}

function AddModal() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(clustersOverviewPageSelector);

  const { data } = useListClustersV1ClustersGetQuery(queryParms);

  const clusters: ClusterListModel[] = data?.data || [];

  if (state.modal.modalType === "addCluster") {
    return (
      <Modal open={true} close={() => dispatch(closeModal())}>
        <AddEditClusterForm
          clustersList={clusters}
          submitText="Add cluster"
          heading="Add cluster"
          request={notAsked()}
          onClose={() => dispatch(closeModal())}
          onSubmit={(values) => dispatch(addCluster(values))}
        />
      </Modal>
    );
  }
}

type Props = {
  filter: string;
};

export function ClustersTable(props: Props) {
  const { filter } = props;

  const { data, isError, isLoading, isFetching } =
    useListClustersV1ClustersGetQuery(queryParms);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const clusters = data?.data || [];
  const totalRows = clusters.length;

  const filterQuery = filter.toLowerCase().trim();

  const filteredClusters = useMemo(() => {
    if (filterQuery !== "") {
      return clusters.filter((row) => {
        return (
          row.region_code.toLowerCase().includes(filterQuery) ||
          row.region.toLowerCase().includes(filterQuery) ||
          row.cloud_provider.toLowerCase().includes(filterQuery) ||
          row.fqdn.toLowerCase().includes(filterQuery)
        );
      });
    }
    return clusters;
  }, [clusters, filterQuery]);

  // Group clusters by region
  const groupedByRegions = useMemo(() => {
    return filteredClusters.reduce((acc: Record<string, any[]>, cluster) => {
      const region = cluster.region || "Unknown";
      if (!acc[region]) {
        acc[region] = [];
      }
      acc[region].push(cluster);
      return acc;
    }, {});
  }, [filteredClusters]);

  const flattenedGroupedClusters = useMemo(() => {
    return Object.values(groupedByRegions).flat();
  }, [groupedByRegions]);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "region",
        header: "Region",
        enableSorting: true,
      },
      {
        accessorKey: "region_code",
        header: "Region Code",
        enableSorting: true,
      },
      {
        accessorKey: "cloud_provider",
        header: "Cloud Provider",
        cell: (info: any) => <CloudProvider value={info.getValue()} />,
        enableSorting: true,
      },
      {
        accessorKey: "fqdn",
        header: "Fqdn",
        enableSorting: true,
      },
      {
        accessorKey: "enable_cloudflare_dns",
        header: "Cloudflare DNS enabled",
        cell: (info: any) => <YesNo value={info.getValue() || false} />,
        enableSorting: true,
      },
      {
        accessorKey: "instance_count",
        header: "Count",
        cell: (info: any) => (
          <span
            className={
              info.getValue() > 200
                ? info.getValue() > 250
                  ? styles.high
                  : styles.medium
                : styles.low
            }
          >
            {info.getValue()}
          </span>
        ),
        enableSorting: true,
      },
    ],
    []
  );

  const table = useReactTable({
    data: flattenedGroupedClusters,
    columns,
    state: {
      pagination,
      sorting,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    pageCount: Math.ceil(flattenedGroupedClusters.length / pagination.pageSize),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  const goToCluster = (clusterId: string) => {
    router.navigate(`/cluster/${clusterId}`);
  };

  if (isLoading) {
    return <PageLoading />;
  }

  if (isError) {
    return <Error>Something went wrong</Error>;
  }

  const fetchingStyles = isFetching ? { opacity: "0.8" } : {};

  const paginationStatus = {
    from: pagination.pageIndex * pagination.pageSize + 1,
    to: Math.min((pagination.pageIndex + 1) * pagination.pageSize, totalRows),
    total: totalRows,
    prev: table.getCanPreviousPage() ? pagination.pageIndex - 1 : null,
    next: table.getCanNextPage() ? pagination.pageIndex + 1 : null,
  };

  return (
    <Flex gap="3" direction="column" style={fetchingStyles}>
      <Table.Root variant="surface" data-testid="results-table">
        <Table.Header>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.ColumnHeaderCell
                  key={header.id}
                  colSpan={header.colSpan}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <Flex align="center">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    <SortArrows
                      upActive={header.column.getIsSorted() === "asc"}
                      downActive={header.column.getIsSorted() === "desc"}
                      onUpClick={() => {}}
                      onDownClick={() => {}}
                    />
                  </Flex>
                </Table.ColumnHeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>

        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row
              key={row.id}
              onClick={() => goToCluster(row.original.id)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  goToCluster(row.original.id);
                }
              }}
              tabIndex={0}
              className={styles.row}
            >
              {row.getVisibleCells().map((cell) => (
                <Table.Cell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>

      <Flex justify="end">
        <Pagination
          status={paginationStatus}
          paginate={(page) => table.setPageIndex(page)}
          testIdPrefix="clusters-table"
        />
      </Flex>
    </Flex>
  );
}
